import { SignJWT,jwtVerify } from 'jose'
import 'dotenv/config'


const secret = [process.env.VUE_APP_JWT_REQUEST_SECRET]

export const CreateToken = async (secretIndex = 0,claim = {}, exp = "15m")=> {
    let toBase64 =secretIndex ==1 ? secret[secretIndex] : Buffer.from(secret[secretIndex], 'base64').toString('binary')

    const jwt = await new SignJWT(claim)
    .setProtectedHeader({ alg: 'HS256', typ : 'JWT' })
    .setIssuedAt()
    .setExpirationTime(exp)
    .sign(Uint8Array.from(toBase64, b => b.charCodeAt(0)))
    // .sign(toBase64)
    return jwt
}


export const DecryptToken = async (token,secretIndex = 0) =>{
    if(!token){
        return null;
    }
    try{
        const toBase64 = secretIndex ==1 ? secret[secretIndex] : Buffer.from(secret[secretIndex], 'base64').toString('binary')
        const { payload, protectedHeader } = await jwtVerify(token, Uint8Array.from(toBase64, b => b.charCodeAt(0)))
    
        return {
            payload: payload, 
            header : protectedHeader
        }
    }
    catch(err)
    {
        console.log(err)
        return null
    }
}

