<template>
  <div class="">
    <h1>Page Not Found </h1>
  </div>
</template>


<script>
export default {
  created : function(){
    const {redirectOnce} = this.$route.query 
    if(!redirectOnce){
      let push
      if(window.location.href.indexOf('?') > -1){
        push = window.location.href.replace(window.location.origin,'') + '&redirectOnce=true'
      }
      else{
        push = window.location.href.replace(window.location.origin,'') + '?redirectOnce=true'
      }
      
      this.$router.push(push)
    }
    
  }
}
</script>
