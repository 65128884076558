<template>
  <!-- <div id="nav">
    <router-link to="/">Home</router-link> 
    <router-link to="/Map">Map</router-link> 
  </div> -->
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  padding-right: 10px;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

a.am5exporting-icon,
.am5exporting-list {
  background: #f1f1f1;
  bottom: 5px!important;
}

a.am5exporting-icon:hover,
.am5exporting-menu-open a.am5exporting-icon,
.am5exporting-item a:hover {
  background: #8a8a8a;
  opacity: 0.8;
}

a.am5exporting-icon,a.am5exporting-icon {
  background: transparent;
  border: 1px solid #8a8a8a;
  opacity: 0.45;
}

a.am5exporting-valign-bottom {
  bottom: 5px !important;
  right: 10px !important;
}

.am5exporting-list.am5exporting-align-right {
  margin-right: 46px !important;
  margin-bottom: 10px !important;
}

.am5exporting-menu path {
    fill: rgb(203, 203, 203)!important;
}

.modal.fade {
  z-index: 10000000 !important;
}
#style >a:hover {
    color:white;
}
</style>

<script>
// Auth disabled. If you want to enable just remove slashes.
// import {CheckSession} from '@/actions/authActions.js'
// import { useCookies } from "vue3-cookies"
import 'dotenv/config'

export default {
  name: 'App',
  created : function(){
    // this.TestSession()
  },
  methods : {
    // TestSession (){
    //   const self = this
    //   const { cookies } = useCookies()
    //   if(cookies.get('_auzer')){
    //     CheckSession().then((res)=>{
    //       if(!res){
    //         cookies.set('_redirreq', self.$route.fullPath ,'15min','/','',true,'Strict')
    //         cookies.remove('_auzer')
    //         window.location.href = process.env.VUE_APP_REST_MAIN_API + "/auth/login"
    //       }
    //     })
    //   }

    // }
  }
}
</script>
