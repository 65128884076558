<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
     <div id="chartdiv">
        
     </div>
  </div>
</template>


<script>
import * as am5 from "@amcharts/amcharts5"
import * as am5map from "@amcharts/amcharts5/map"
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow" 
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {GetOfficeProjectCounts} from '@/actions/authActions.js'
import {CountryCodes} from '@/helpers/countrycodes.js'

export default {
  name: 'MapV',
  props: {
    msg: String,
  },
  mounted : async function () {

    const self = this
    let root = am5.Root.new('chartdiv');
    // let chart = root.container.children.push(
    //   am5map.MapChart.new(root, {})
    // )

    //Get Map Data from Project API
 
  const mapData = await GetOfficeProjectCounts().catch()
  const Countries = await CountryCodes()

    const countries = []
    // Match up the countries
    mapData.forEach(item => {
        Countries.forEach(e => {
          if( item.country === e.country ){
            countries.push({
              country: item.country,
              code: e.code,
              totalProjects: item.totalProjects
            })
          }
        })
    })
    let colors = am5.ColorSet.new(root, {});
    //Remap Map Data to fit fields in AMCHARTS
    self.mapData = countries.map((m)=>{
      return {
        id: m.code, //NOTE : this is need to be match to our records EX. Philippines = PH
        name: m.country,
        value: m.totalProjects,
        circleTemplate: { fill: colors.getIndex(8) }
      }
    })

    root.setThemes([
      am5themes_Animated.new(root)
    ]);

    
  // Create the map chart
  // https://www.amcharts.com/docs/v5/charts/map-chart/
  let chart = root.container.children.push(
    am5map.MapChart.new(root, {
      panX: "rotateX",
      panY: "rotateY",
      projection: am5map.geoMercator()
    })
  );

  // Create series for background fill
  // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
  let backgroundSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {}));
  backgroundSeries.mapPolygons.template.setAll({
    fill: root.interfaceColors.get("alternativeBackground"),
    fillOpacity: 0,
    strokeOpacity: 0
  });
  // Add background polygo
  // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/#Background_polygon
  backgroundSeries.data.push({
    geometry: am5map.getGeoRectangle(90, 180, -90, -180)
  });

  // Create main polygon series for countries
  // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
  let polygonSeries = chart.series.push(
    am5map.MapPolygonSeries.new(root, {
      geoJSON: am5geodata_worldLow
    })
  );

  polygonSeries.mapPolygons.template.setAll({
    fill: root.interfaceColors.get("alternativeBackground"),
    fillOpacity: 0.15,
    strokeWidth: 0.5,
    stroke: root.interfaceColors.get("background")
  });

  // Create polygon series for circles
  // https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
  let circleTemplate = am5.Template.new({
    tooltipText: "{name}: {value}"
  });

  let bubbleSeries = chart.series.push(
    am5map.MapPointSeries.new(root, {
      calculateAggregates: true,
      valueField: "value",
      polygonIdField: "id"
    })
  );

  bubbleSeries.bullets.push(function () {
    return am5.Bullet.new(root, {
      sprite: am5.Circle.new(root, {
        radius: 10,
        templateField: "circleTemplate"
      }, circleTemplate)
    });
  });

  bubbleSeries.set("heatRules", [{
    target: circleTemplate,
    min: 3,
    max: 30,
    key: "radius",
    dataField: "value"
  }]);

  

    // let myColors = {
    //   yellow: '#FFFF00',
    //   red: '#FF0000'
    // }

  bubbleSeries.data.setAll(self.mapData);

  // Add globe/map switch
  let cont = chart.children.push(am5.Container.new(root, {
    layout: root.horizontalLayout,
    x: 20,
    y: 40
  }));

  cont.children.push(am5.Label.new(root, {
    centerY: am5.p50,
    text: "Map"
  }));

  let switchButton = cont.children.push(
    am5.Button.new(root, {
      themeTags: ["switch"],
      centerY: am5.p50,
      icon: am5.Circle.new(root, {
        themeTags: ["icon"]
      })
    })
  );

  switchButton.on("active", function () {
    if (!switchButton.get("active")) {
      chart.set("projection", am5map.geoMercator());
      backgroundSeries.mapPolygons.template.set("fillOpacity", 0);
    } else {
      chart.set("projection", am5map.geoOrthographic());
      backgroundSeries.mapPolygons.template.set("fillOpacity", 0.1);
    }
  });

  cont.children.push(
    am5.Label.new(root, {
      centerY: am5.p50,
      text: "Globe"
    })
  );

  // Make stuff animate on load
  chart.appear(1000, 100);


  },
  data(){
    return {
      mapData : []
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#chartdiv {
    width: 100%;
    height: 600px;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
