import axios from "axios"

axios.defaults.withCredentials = true
const MAIN_API = process.env.VUE_APP_REST_MAIN_API
const PROJECT_API = process.env.VUE_APP_REST_PROJECT_API
// const TOKEN_INDEX_MAIN = 0


export const CheckSession = async () =>{
    return axios.get(`${MAIN_API}/auth/validate`)
    .then(()=>{
        return true
    }).catch(()=>{ return false })
}
//type = 1 means that Offices are grouped by countries , type = 2 means the return will be a list of offices 
export const GetOfficeProjectCounts = async () =>{
    return axios.get(`${PROJECT_API}/api/project/totals?type=1`)
    .then((res)=>{
        return res.data
    }).catch(()=>{ return [] })
}



