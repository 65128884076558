<template>
  <div class="map">
     <map-v msg="Project Count Map"/>
  <div> 

  </div>
  </div>
</template>

<script>
// @ is an alias to /src 
import MapV from "@/components/MapV.vue"
// import * as am5 from "@amcharts/amcharts5"
// import * as am5map from "@amcharts/amcharts5/map" 
// import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow"

export default {
  name: 'Map',
  components: {
    MapV
  },
  data() {
      return {
          mus: 'return'
      }
  },
  mounted() {
    // let root = am5.Root.new('chartdiv');
    // let chart = root.container.children.push(
    //   am5map.MapChart.new(root, {})
    // )

    // chart
  }
}
</script>

<style scoped>

</style>