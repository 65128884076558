import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../views/NotFound.vue'
import Unauthorized from '../views/Unauthorized.vue'
import Map from '../views/Map.vue'
import Offices from '../views/Offices.vue'
import ProjectCities from '../views/ProjectCities.vue'
import WorldMap from '../views/WorldMap.vue'
import Auth from '../middleware/auth.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/map',
    name: 'Map',
    component: Map,
    meta : {
      middleware : Auth
    }
  },
  {
    path: '/projects/:office',
    name: 'ProjectCities',
    component: ProjectCities,
  },
  {
    path: '/offices',
    name: 'Offices',
    component: Offices,
  },
  {
    path: '/projects/world',
    name: 'WorldMap',
    component: WorldMap,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFound
  },
  {
    path: '/Unauthorized',
    name: 'not-found',
    component: Unauthorized
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

function nextFactory (context, middleware, index){
  const subsequentMiddleware = middleware[index]

  if(!subsequentMiddleware){
    return context.next
  }

  return(...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({...context, nextMiddleware})
  }
}

router.beforeEach((to,from,next) =>{
  if(to.meta.middleware){
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = {from, next, router, to}
    const nextMiddleware = nextFactory(context,middleware,1)
    return middleware[0]({...context, nextMiddleware})
  }

  return next()
})

export default router
