<template>
  <div class="hello">
    <div
      id="chartdiv"
      style="position: relative"
      :style="
        this.$route.query.width && this.$route.query.height
          ? [
              {
                width: this.$route.query.width + 'px',
                height: this.$route.query.height + 'px',
              },
            ]
          : [{ width: '100%', height: '100vh' }]
      "
    >
    </div>
  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from "axios";
// import {GetOfficeProjectCounts} from '@/actions/authActions.js'
// import {CountryCodes} from '@/helpers/countrycodes.js'

export default {
  name: "ProjectCities",
  props: {
    msg: String,
  },
  data() {
    return {
      mapData: [],
      location: '',
      figure: [],
      region: "APAC",
      landmarks: [],
      newDataPlaces: [],
      totalProjects: [],
    };
  },
  beforeCreate() {
    // console.log(this.$route.params.office);
  },
  mounted: async function () {
    const self = this;
  
    // figure out on only one function !
    // self.totalProjects.forEach(elm => {console.log(elm.office)})


    // const self = this
    let root = am5.Root.new("chartdiv");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "translateX",
        panY: "translateY",
        projection: am5map.geoMercator(),
      })
    );


    // Dark & white version 
    const style = this.$route.query.style ? this.$route.query.style : 0; // 0 = dark version (default), 1 = white version
        // AQ: Exclude Antartica
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
        fill: am5.color("#232323"),

        stroke: am5.color("#232323"),
        strokeWidth: 1,
      })
    );

    await Promise.all([
          axios.get(process.env.VUE_APP_REST_API_URL+"/api/projectCities/"+this.$route.params.office).then((offices) => {
            self.dataPlaces = offices.data;
            // Put data into the table on backend
            createPlaces(self.dataPlaces[0]);
            createCities(self.dataPlaces[0].cities);
            axios.get(process.env.VUE_APP_REST_API_URL+"/api/offices").then((landmarks) => {
                self.landmarks = landmarks.data;
                landmarksSet(self.landmarks)
            })
          }) 
    ]);
    
  
    // Map Style
    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color("#292929"),
    });

    polygonSeries.mapPolygons.template.states.create("active", {
      fill: am5.color("#232323"),
    });

    chart.chartContainer.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(style == 0 ? "#1a1a1a" : "#3b3c3d"), // dark: #1a1a1a, white: #3b3c3d
        fillOpacity: 1,
      })
    );
      polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      toggleKey: "active",
      interactive: true,
    });
 

    // Point Series Auto Zoom
    // polygonSeries.events.on("datavalidated", function () {
    //   chart.zoomToGeoPoint(
    //     {
    //       longitude: 147.62,
    //       latitude: 2,
    //     },
    //     3,
    //     0
    //   );
    // });

       const TextX = {
        sanfrancisco: am5.percent(104), london: am5.percent(105), 
        denver: am5.percent(25),vancouver: am5.percent(104), delhi: am5.percent(104), guangzhou: am5.percent(75), 
        manila: am5.percent(15), hongkong: am5.percent(100), mumbai: am5.percent(104), melbourne: am5.percent(104), raleighdurham: am5.percent(15), chengdu: am5.percent(85),
        bangalore: am5.percent(100), kualalumpur: am5.percent(104), beijing: am5.percent(15), shanghai: am5.percent(10), hyderabad: am5.percent(1),
      }

      const TextY = {
        shenzhen: am5.percent(45),
        singapore: am5.percent(50), kualalumpur: am5.percent(60),
        toronto: am5.percent(60), hongkong: am5.percent(18), london: am5.percent(50), newyork: am5.percent(40), amsterdam: am5.percent(70),
        vancouver: am5.percent(50), delhi: am5.percent(50), guangzhou: am5.percent(105), mumbai: am5.percent(50), melbourne: am5.percent(50), bangalore: am5.percent(25), sanfrancisco: am5.percent(50), 
        beijing: am5.percent(100), taipei: am5.percent(60), shanghai: am5.percent(95), australia: am5.percent(50), sydney: am5.percent(50), hyderabad: am5.percent(25), chengdu: am5.percent(105),  denver: am5.percent(105),
      }

    function createPlaces(elm) {
        let pointSeriesPlaces = chart.series.push(
          am5map.MapPointSeries.new(root, {
            latitudeField: "lat",
            longitudeField: "lng",
          })
        );

        pointSeriesPlaces.data.setAll([
          {
            lng: elm.geometry.lng,
            lat: elm.geometry.lat,
            // src: process.env.VUE_APP_REST_API_URL+elm.props.img.url,
            text: elm.props.name,
          },
        ]);
        // Pictures
        pointSeriesPlaces.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Picture.new(root, {
            //   width: elm.props.img.width,
            //   height: elm.props.img.height,
              centerX: elm.props.name == "Singapore" ? am5.percent(10) : elm.props.name == "KualaLumpur" ? am5.percent(120) : am5.percent(90),
              centerY: elm.props.name == "Delhi" || elm.props.name == "New York" ? am5.percent(105) : am5.percent(110),
            //   src: typeof elm.props.img.url !== 'undefined' ? process.env.VUE_APP_REST_API_URL+elm.props.img.url : '',
              opacity: elm.props.name == "Dubai" ? 0.95 : elm.props.name == "Japan" ? 1 : 0.35,
            }),
          });
        });
      var office = self.slugify(elm.props.name)
        // Texts
        console.log(elm.totalProjects)
        if (elm.point && elm.totalProjects > 0) {
          pointSeriesPlaces.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                centerX: TextX[office] !== 'undefined' ? TextX[office] : am5.percent(15),
                centerY: TextY[office] !== 'undefined' ? TextY[office] : am5.percent(5),
                text: elm.props.name + ' (' + elm.totalProjects + ')',
                fontSize: 14,
                fontWeight: "400",
                fontFamily: "Open Sans",
                fill: "#fff",
                marginLeft: '15px'
              }),
            });
          });

        }

        var name = elm.props.name;
        name;
        if (elm.point && elm.totalProjects > 0) {
          pointSeriesPlaces.bullets.push(function () {
            let circle = am5.Circle.new(root, {
              radius: 6.5,
              fill: am5.color("#c77f44"),
              // tooltipText: name,
              cursorOverStyle: "pointer",
              shadowColor: am5.color("#FFF"),
              shadowBlur: "35",
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowOpacity: 1,
              // strokeOpacity: 0
            });

            circle.events.on("click", function () {
              console.log(elm.props.name);
              // click event
              alert("Clicked on " + elm.props.name);
            });
            return am5.Bullet.new(root, {
              sprite: circle,
            });
          });
        } 
    }
    function createCities(data) {
         data.forEach((elm) => {
        let pointSeriesCities = chart.series.push(
          am5map.MapPointSeries.new(root, {
            latitudeField: "lat",
            longitudeField: "lng",
          })
        );

        pointSeriesCities.data.setAll([
          {
            lng: elm.lng,
            lat: elm.lat,
            // src: process.env.VUE_APP_REST_API_URL+elm.props.img.url,
            text: elm.city,
          },
        ]);
      var office = self.slugify(elm.city)
        // Texts
        if (elm.totalProjects > 0) {
          pointSeriesCities.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                centerX: TextX[office] !== 'undefined' ? TextX[office] : am5.percent(15),
                centerY: TextY[office] !== 'undefined' ? TextY[office] : am5.percent(5),
                text: elm.totalProjects > 0 && self.dataPlaces[0].props.name == elm.city ? '' : elm.city +' ('+elm.totalProjects+')',
                fontSize: 14,
                fontWeight: "400",
                fontFamily: "Open Sans",
                fill: "#fff",
                marginLeft: '15px'
              }),
            });
          });

        }

        var name = elm.city;
        name;
        if (elm.totalProjects > 0 && elm.city != self.dataPlaces[0].props.name) {
          pointSeriesCities.bullets.push(function () {
            let circle = am5.Circle.new(root, {
              radius: 6.5,
              fill: am5.color("#ddd"),
              // tooltipText: name,
              cursorOverStyle: "pointer",
              shadowColor: am5.color("#FFF"),
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowOpacity: 1,
              // strokeOpacity: 0
            });

            circle.events.on("click", function () {
              console.log(elm.city);
              // click event
              alert("Clicked on " + elm.city);
            });
            return am5.Bullet.new(root, {
              sprite: circle,
            });
          });
        }
      });
    }

    function landmarksSet(data) {
         data.forEach((elm) => {
        let pointSeriesLandmarks = chart.series.push(
          am5map.MapPointSeries.new(root, {
            latitudeField: "lat",
            longitudeField: "lng",
          })
        );

        pointSeriesLandmarks.data.setAll([
          {
            lng: elm.geometry.lng,
            lat: elm.geometry.lat,
            src: process.env.VUE_APP_REST_API_URL+elm.props.img.url
          },
        ]);
        // Pictures
        pointSeriesLandmarks.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Picture.new(root, {
              width: elm.props.img.width,
              height: elm.props.img.height,
              centerX: elm.props.name == "Singapore" ? am5.percent(10) : elm.props.name == "Kuala Lumpur" ? am5.percent(120) : am5.percent(90),
              centerY: elm.props.name == "Delhi" || elm.props.name == "New York" ? am5.percent(105) : am5.percent(110),
              src: typeof elm.props.img.url !== 'undefined' ? process.env.VUE_APP_REST_API_URL+elm.props.img.url : '',
              opacity: elm.props.name == "Dubai" ? 0.95 : elm.props.name == "Japan" ? 1 : 0.35,
            }),
          });
        });
    });
    }

 
    // Add zoom control
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-pan-zoom/#Zoom_control
    // chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    // Set clicking on "water" to zoom out
    chart.chartContainer.get("background").events.on("click", function () {
      chart.goHome();
    });

    // Make stuff animate on load
    chart.appear(1000, 100);
  },
  methods: {
    slugify: (str) => {
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
        .replace("-", "");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hidden {
 display:none!important;
}
/* #chartdiv {
    width: 750px;
    height: 750px;
} */

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
