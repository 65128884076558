
import { useCookies } from "vue3-cookies"
import 'dotenv/config'
import { DecryptToken } from "../helpers/jwt.js" 
export default function Auth({ next, router, to }) {

    const REST_API = process.env.VUE_APP_REST_MAIN_API
    const { cookies } = useCookies()
    const code = to.query._uzrCode
    if(code){
        DecryptToken(code,0).then((res) =>{
            if(res){
                cookies.set('_auzer', 'checker','1d','/','',true,'Strict')
                router.push(cookies.get('_redirreq'))
            }else{
                router.push("not-found")
            }
        })
    }else{
        if(!cookies.get('_auzer')){
            console.log('goes here')
            cookies.set('_redirreq', to.fullPath ,'15min','/','',true,'Strict')
            window.location.href = REST_API + "/auth/login"
        }
    }
  
    return next()
}