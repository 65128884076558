<template>
  <div class="map">
    <div
      id="chartdiv"
      style="position: relative"
      :style="
        this.$route.query.width && this.$route.query.height
          ? [
              {
                width: this.$route.query.width + 'px',
                height: this.$route.query.height + 'px',
              },
            ]
          : [{ width: '100%', height: '100vh' }]
      "
    >
      <!-- $route.params.location -->
      <div :class="this.$route.query.width >= 1024 ? 'mapFigures' : 'mapFigures-1'" class="hidden">
        <div :class="this.$route.query.width >= 1024 ? 'employees' : 'employees-1'" >
          <p class="first">Employees</p>
          <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">{{ figure.total_employees }}</h1>
          <p>staff in the {{ figure.location }} office</p>
        </div>
        <div :class="this.$route.query.width >= 1024 ? 'location' : 'location-1'">
          <p class="first">Location</p>
          <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">{{ figure.total_offices }}</h1>
          <p>numbers of M Moser offices in {{ region }}</p>
        </div>
        <div :class="
            this.$route.query.width >= 1024
              ? 'repeatClients'
              : 'repeatClients-1'
          ">
          <p class="first">Repeat Clients</p>
          <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">{{ figure.total_repeat_clients }}</h1>
          <p>of our work is repeat client business</p>
        </div>
        <div :class="this.$route.query.width >= 1024 ? 'delivered' : 'delivered-1'">
          <p class="first">Delivered</p>
          <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">{{ figure.total_delivered }}</h1>
          <p>square feet of over 1,000 projects completed</p>
        </div>
      </div>
    </div>
    <div style="position: absolute; top:1rem; right:1rem;">
        <div class="zoom-in" @click="zoomInBox = !zoomInBox">
            Zoom <ZoomIn />
        </div>
        <div class="zoom-in-box" v-if="zoomInBox">
            <span class="item" @click="zoomToGeoPoint(147.62, 2, 3)">APAC</span>
            <span class="item" @click="zoomToGeoPoint(9, 49, 3)">EMEA</span>
            <span class="item" @click="zoomToGeoPoint(-101, 39, 3)">AMER</span>
            <span class="item" @click="zoomToGeoPoint(null, 39, 3)">GLOBAL</span>
        </div>
    </div>

    <div style="position: absolute; bottom:9.5px; right:3.25rem;">
        <div data-bs-toggle="modal" data-bs-target="#exampleModal">
            <button class="btn btn-outline-secondary" style="padding-top: 0.05rem!important; padding-bottom: 0.05rem!important;">Share Map</button>
        </div>
    </div>
    <div style="position: absolute; bottom:9.5px; right:10.25rem;" id="style">
            <a v-if="!mapStyle" class="btn btn-outline-secondary" style="padding-top: 0.05rem!important; padding-bottom: 0.05rem!important;" href="?style=1"> <light-icon /></a>
            <a v-if="mapStyle" class="btn btn-outline-secondary" style="padding-top: 0.05rem!important; padding-bottom: 0.05rem!important;" href="?"> <dark-icon /></a>
    </div>
<!-- Modal -->
<div class="modal fade in" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center justify-between">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Share Current Map View</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
         <div class="input-group mb-3" id="map_link">
            <input type="text" class="form-control" v-model="map_url" ref="map_link" aria-describedby="basic-addon2">
         </div>
            <span class="input-group-text btn btn-outline-secondary" id="basic-addon2" @click="copyMapLink">Copy</span>
      </div>
    </div>
  </div>
</div>
  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import * as am5plugins_exporting from "@amcharts/amcharts5/plugins/exporting";
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from "axios";
import ZoomIn from "@/components/svg/ZoomIn.vue";
import LightIcon from "@/components/svg/LightIcon.vue";
import DarkIcon from "@/components/svg/DarkIcon.vue";
// import {GetOfficeProjectCounts} from '@/actions/authActions.js'
// import {CountryCodes} from '@/helpers/countrycodes.js'

export default {
  name: "Offices",
  components: {
    ZoomIn,
    LightIcon,
    DarkIcon,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      mapData: [],
      mapStyle: 0,
      figure: [],
      region: "APAC",
      // dataPlaces: [],
      newDataPlaces: [],
      totalProjects: [],
      zoomInBox: false,
      chart: null,
      map_url: 'https://map-view.mmoser.app/offices', 
    };
  },
  beforeCreate() {
    // console.log(this.$route.params.office);
  },
    methods: {
    slugify: (str) => {
      return str
        .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
        .replace("-", "");
    },

    zoomToGeoPoint(longitude, latitude, zoomLevel, query = false) {
        this.zoomInBox = false;
        if(!longitude) {
            this.chart.goHome()
            return
        }
       if(query == false) {
         this.chart.goHome()
        }
        setTimeout(() => {
            this.chart.zoomToGeoPoint(
            {
                longitude: longitude,
                latitude: latitude,
            },
            zoomLevel,
            query == true ? 1000 : 0
            );
        }, 1000);
    },
    copyMapLink(e) {
        this.$refs.map_link.select();
        document.execCommand('copy');
        e.target.innerHTML = 'Copied!'
        setTimeout(() => {
            e.target.innerHTML = 'Copy'
        }, 1000);
    }
  },
  mounted: async function () {
    const self = this;
  
    // figure out on only one function !
    // self.totalProjects.forEach(elm => {console.log(elm.office)})

    // const self = this
    let root = am5.Root.new("chartdiv");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create the map chart
    // https://www.amcharts.com/docs/v5/charts/map-chart/
    let chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: "translateX",
        panY: "translateY",
        projection: am5map.geoMercator(),
      })
    );
    


    // chart.events.on("globalpointermove", function(ev) {
    //     console.log('REAL:',chart.invert(ev.point))
    // });

    // Dark & white version 
    this.mapStyle = this.$route.query.style ? this.$route.query.style : 0; // 0 = dark version (default), 1 = white version
        // AQ: Exclude Antartica
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
        fill: am5.color(self.mapStyle == 0 ? "#232323" : "#f0f0f0"),
        stroke: am5.color(self.mapStyle == 0 ? "#1a1a1a" : "#ffffff"),
        strokeWidth: 1,
      })
    );
  
    // Exporting PNG, JPG, PDF and Print
      var exporting = am5plugins_exporting.Exporting.new(root, {
        menu: am5plugins_exporting.ExportingMenu.new(root, {
        align: "right",
        valign: "bottom",
        }),
        filePrefix: "Map-" + (new Date()).toLocaleDateString('en-US'),
        pngOptions: {
            quality: 1
        },
        jpgOptions: {
            quality: 1
        }
    });
    chart.set("exporting", exporting);


    chart.events.on("globalpointermove", function(ev) {
        // console.log('center:', ev.target._centerLocation)
        // console.log('zoom:', ev.target._downZoomLevel)
        if(ev.target._centerLocation[0] == 0) {
            return;
        }
        self.map_url = 'https://map-view.mmoser.app/offices?longitude='+ev.target._centerLocation[0]+'&latitude='+ev.target._centerLocation[1]+'&zoom='+ev.target._settings.zoomLevel
    });
// chart.events.on("zoomLevelChange", function(ev) {
//   let zoomLevel = ev.target.zoomLevel;
//   let centerCoords = ev.target.zoomGeoPoint;
//   console.log("Zoom Seviyesi: " + zoomLevel);
//   console.log("Merkez Koordinatlar: ", centerCoords);
// });
        // chart.events.on("globalpointermove", function(ev) {
        //     console.log('REAL:',chart.invert(ev.point))
        // });
        
    // Map Style
    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(self.mapStyle == 0 ? "#262626" : "#f5f5f5"),
    });

    polygonSeries.mapPolygons.template.states.create("active", {
      fill: am5.color(self.mapStyle == 0 ? "#262626" : "#ffffff"),
    });

    chart.chartContainer.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(self.mapStyle == 0 ? "#1a1a1a" : "#ffffff"), // dark: #1a1a1a, white: #ffffff
        fillOpacity: 1,
      })
    );
      polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      toggleKey: "active",
      interactive: true,
    });
 
    if(this.$route.query.longitude && this.$route.query.latitude) {
        self.zoomToGeoPoint(this.$route.query.longitude, this.$route.query.latitude, this.$route.query.zoom, true)
    }

    this.chart = chart;
    // Point Series Auto Zoom
    // polygonSeries.events.on("datavalidated", function () {
    //   chart.zoomToGeoPoint(
    //     {
    //       longitude: 147.62,
    //       latitude: 2,
    //     },
    //     3,
    //     0
    //   );
    // });
    await Promise.all([
      axios.get( process.env.VUE_APP_REST_API_URL+"/api/totalProjects" )
        .then((total) => {
          self.totalProjects = total.data;
          axios.get(process.env.VUE_APP_REST_API_URL+"/api/offices").then((offices) => {
            self.dataPlaces = offices.data;
            this.newDataPlaces = this.dataPlaces.map((place) => {
              self.totalProjects.map((total) => {
                if ( self.slugify(place.name) == self.slugify(total.office) ) {
                  place.totalProjects = total.totalProjects;
                }
              });
              return place;
            });
            // Put data into the table on backend
            createPlaces(this.newDataPlaces);
            //  axios.get(process.env.VUE_APP_REST_API_URL+"/api/figures/"+self.$route.params.office).then((office) => {

            // self.figure = office.data[0].figure;
            //  })
       
          })
        }),
    ]);
 
      const TextX = {
        sanfrancisco: am5.percent(95), london: am5.percent(100), 
        denver: am5.percent(25),vancouver: am5.percent(100), delhi: am5.percent(90), guangzhou: am5.percent(76), perth: am5.percent(80), bangkok: am5.percent(10),
        manila: am5.percent(15), hongkong: am5.percent(99), mumbai: am5.percent(90), melbourne: am5.percent(93), raleighdurham: am5.percent(15), chengdu: am5.percent(85),
        bangalore: am5.percent(95), kualalumpur: am5.percent(95), beijing: am5.percent(-5), shanghai: am5.percent(-5), hyderabad: am5.percent(1), singapore: am5.percent(-3),
        hamilton: am5.percent(5)
      }

      const TextY = {
        shenzhen: am5.percent(45), dubai: am5.percent(40), brisbane: am5.percent(45), tokyo: am5.percent(45), seoul: am5.percent(60), perth: am5.percent(85), jakarta: am5.percent(60),
        singapore: am5.percent(50), kualalumpur: am5.percent(60), pune: am5.percent(65), bangkok: am5.percent(105), vietnam: am5.percent(60), cambodia: am5.percent(60),
        toronto: am5.percent(60), hongkong: am5.percent(50), london: am5.percent(50), newyork: am5.percent(40), amsterdam: am5.percent(50), tauranga: am5.percent(40), auckland: am5.percent(75), hamilton: am5.percent(30),
        vancouver: am5.percent(50), delhi: am5.percent(15), guangzhou: am5.percent(105), mumbai: am5.percent(50), melbourne: am5.percent(50), bangalore: am5.percent(25), sanfrancisco: am5.percent(50), 
        beijing: am5.percent(50), taipei: am5.percent(60), shanghai: am5.percent(50), australia: am5.percent(50), sydney: am5.percent(50), hyderabad: am5.percent(25), chengdu: am5.percent(105),  denver: am5.percent(105),
        paris: am5.percent(50)
      }

    function calculateRadius(totalProjects, maxTotalProjects) {
        let minRadius = 4;
        let maxRadius = 15;
        let radius = (totalProjects / maxTotalProjects) * (maxRadius - minRadius) + minRadius;
        return radius;
    }

    function calculateOpacity(totalProjects, maxTotalProjects, minTotalProjects) {
        let minOpacity = 0.4;
        let maxOpacity = 0.75;
        return (maxTotalProjects - totalProjects) / (maxTotalProjects - minTotalProjects) * (maxOpacity - minOpacity) + minOpacity;
    }
    function createPlaces(data) {
        let maxTotalProjects = Math.max(...data.map(item => item.totalProjects));
        let minTotalProjects = Math.min(...data.map(item => item.totalProjects));
      data.forEach((elm) => {
    let pointSeriesPlaces = chart.series.push(
        am5map.MapPointSeries.new(root, {
            latitudeField: "lat",
            longitudeField: "lng",
        })
    );

        pointSeriesPlaces.data.setAll([
          {
            lng: elm.geometry.lng,
            lat: elm.geometry.lat,
            src: process.env.VUE_APP_REST_API_URL+elm.props.img.url,
            text: elm.props.name,
            value: elm.totalProjects,
          },
        ]);
        // Pictures
        pointSeriesPlaces.bullets.push(function () {
          return am5.Bullet.new(root, {
            sprite: am5.Picture.new(root, {
              width: elm.props.img.width,
              height: elm.props.img.height,
              centerX: elm.props.name == "Singapore" ? am5.percent(10) : elm.props.name == "Kuala Lumpur" ? am5.percent(120) : am5.percent(90),
              centerY: elm.props.name == "Delhi" || elm.props.name == "New York" ? am5.percent(105) : am5.percent(110),
              src: typeof elm.props.img.url !== 'undefined' ? elm.props.img.url : '',
              opacity: elm.props.name == "Dubai" ? 0.95 : elm.props.name == "Tokyo" ? 1 : 0.35,
            }),
          });
        });
      var office = self.slugify(elm.props.name)
        // Texts
         pointSeriesPlaces.bullets.push(function () {
            return am5.Bullet.new(root, {
              sprite: am5.Label.new(root, {
                centerX: TextX[office] !== 'undefined' ? TextX[office] : am5.percent(15),
                centerY: TextY[office] !== 'undefined' ? TextY[office] : am5.percent(5),
                text: elm.totalProjects > 0 && elm.type == "Office" ? elm.props.name.toUpperCase()+ ' ('+ elm.totalProjects +')  ' : elm.props.name.toUpperCase(),
                fontSize: 14,
                fontWeight: "500",
                letterSpacing: 5,
                fontFamily: "Open Sans",
                fill: self.mapStyle == 0 ? "#fff" : "#ff7211",
                marginLeft: '0px',
              }),
            });
          });


        var name = elm.props.name;
        name;
        var circleTemplate = am5.Template.new({});
if (elm.type == "Office") {
    let radius = calculateRadius(elm.totalProjects, maxTotalProjects);  //
    let opacity = calculateOpacity(elm.totalProjects, maxTotalProjects, minTotalProjects);
    pointSeriesPlaces.bullets.push(function () {
        let circle = am5.Circle.new(root, {
            radius: radius,
            fill: am5.color("#ff7211"),
            fillOpacity: opacity,
            cursorOverStyle: "pointer",
            shadowColor: am5.color("#FFF"),
            shadowBlur: "44",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowOpacity: 1
        }, circleTemplate);
        return am5.Bullet.new(root, {
            sprite: circle
        });
    });
}

if (elm.type == "Partner") {
    pointSeriesPlaces.bullets.push(function () {
        let circle = am5.Circle.new(root, {
            radius: 5,
            fill: am5.color("#3030fc"),
            fillOpacity: 0.6,
            cursorOverStyle: "pointer",
            shadowColor: am5.color("#FFF"),
            shadowBlur: "44",
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowOpacity: 1
        }, circleTemplate);
        return am5.Bullet.new(root, {
            sprite: circle
        });
    });
}


      });
    }


    let previousPolygon;

    polygonSeries.mapPolygons.template.on("active", function (active, target) {
      if (previousPolygon && previousPolygon != target) {
        previousPolygon.set("active", false);
      }
      if (target.get("active")) {
        polygonSeries.zoomToDataItem(target.dataItem);
      } else {
        chart.goHome();
      }
      previousPolygon = target;
    });

    // Add zoom control
    // https://www.amcharts.com/docs/v5/charts/map-chart/map-pan-zoom/#Zoom_control
    // chart.set("zoomControl", am5map.ZoomControl.new(root, {}));

    // Set clicking on "water" to zoom out
    chart.chartContainer.get("background").events.on("click", function () {
      chart.goHome();
      self.zoomInBox = false;
    });

    // Make stuff animate on load
    chart.appear(1000, 100);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
body {
      text-transform: uppercase!important;
}
.hidden {
 display:none!important;
}
/* #chartdiv {
    width: 750px;
    height: 750px;
} */

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.zoom-in {
    display: flex; 
    align-items: center; 
    gap: 2px; 
    padding: 5px 15px 5px 15px; 
    border: 1px solid rgba(152, 152, 152, 0.2); 
    border-radius: 5px; 
    color:#939393;
    cursor:pointer;
}
.zoom-in:hover {
    background-color: #ddd3;
}
.zoom-in-box {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 2px; 
    padding: 5px 15px 5px 10px;
    border: 1px solid #ddd3; 
    border-radius: 5px; 
    color:#ddd;
    cursor:pointer;
    background-color: #ddd3;
    position: absolute;
    top: 2.25rem;
    z-index: 100;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;
}
.zoom-in-box .item {
    padding: 5px 10px;
    border-radius: 5px;
    /* background-color: #ddd3; */
    color: #939393;
    cursor: pointer;
}
.zoom-in-box .item:hover {
    background-color: #ddd;
    color: #000;
}
</style>
