<template>
  <div class="hello"> 
     <div id="chartdiv" style="position:relative;" :style="this.$route.query.width && this.$route.query.height ? [{width: this.$route.query.width+'px', height: this.$route.query.height+'px'}] : [{width: '100%',height:'100vh'}]">
        <!-- $route.params.location -->
     <!-- <div :class="this.$route.query.width >= 1024 ? 'mapFigures' : 'mapFigures-1'">
            <div :class="this.$route.query.width >= 1024 ? 'employees' : 'employees-1'">
                <p class="first">Employees</p>
                <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">100</h1>
                <p>staff in the {{ this.$route.params.office }} office</p>
            </div>
            <div :class="this.$route.query.width >= 1024 ? 'location' : 'location-1'">
                <p class="first">Location</p>
                <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">17</h1>
                <p>numbers of M Moser offices in {{ region }}</p>
            </div>
            <div :class="this.$route.query.width >= 1024 ? 'repeatClients' : 'repeatClients-1'">
                <p class="first">Repeat Clients</p>
                <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">70%</h1>
                <p>of our work is repeat client business</p>
            </div>
            <div :class="this.$route.query.width >= 1024 ? 'delivered' : 'delivered-1'">
                <p class="first">Delivered</p>
                <h1 :class="this.$route.query.width >= 1024 ? 'h1' : 'h1-1'">16M</h1>
                <p>square feet of over 1,000 projects completed</p>
            </div>
     </div> -->
     </div>

  </div>
</template>
<script>
import * as am5 from "@amcharts/amcharts5"
import * as am5map from "@amcharts/amcharts5/map"
import am5geodata_worldLow from "@amcharts/amcharts5-geodata/worldLow" 
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import axios from 'axios'
// import {GetOfficeProjectCounts} from '@/actions/authActions.js'
// import {CountryCodes} from '@/helpers/countrycodes.js'

export default {
  name: 'World',
  props: {
    msg: String,
  },
  data(){
    return {
      mapData : [],
      mapStyle: 0,
      location: this.$route.params.location,
      region: 'APAC',
      dataPlaces: [],
      newDataPlaces: [],
      totalProjects: []
    }
  },
  beforeCreate() {
     
  },
  mounted: async function () {
    const self = this;
      
    let root = am5.Root.new('chartdiv');
 
    // Set themes
// https://www.amcharts.com/docs/v5/concepts/themes/
root.setThemes([
  am5themes_Animated.new(root)
]);

 
// Create the map chart
// https://www.amcharts.com/docs/v5/charts/map-chart/
// let chart = root.container.children.push(am5map.MapChart.new(root, {
//   panX: "translateX",
//   panY: "translateY",
//   projection: am5map.geoMercator()
// }));

let chart = root.container.children.push(
  am5map.MapChart.new(root, {
    panX: "translateX",
    panY: "translateY",
    projection: am5map.geoNaturalEarth1()
  })
);

    // Dark & white version 
    this.mapStyle = this.$route.query.style ? this.$route.query.style : 0; // 0 = dark version (default), 1 = white version
        // AQ: Exclude Antartica
    let polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        geoJSON: am5geodata_worldLow,
        exclude: ["AQ"],
        fill: am5.color(self.mapStyle == 0 ? "#232323" : "#f0f0f0"),
        stroke: am5.color(self.mapStyle == 0 ? "#1a1a1a" : "#ffffff"),
        strokeWidth: 1,
      })
    );


    // Map Style
    polygonSeries.mapPolygons.template.states.create("hover", {
      fill: am5.color(self.mapStyle == 0 ? "#262626" : "#f5f5f5"),
    });

    polygonSeries.mapPolygons.template.states.create("active", {
      fill: am5.color(self.mapStyle == 0 ? "#262626" : "#ffffff"),
    });

    chart.chartContainer.set(
      "background",
      am5.Rectangle.new(root, {
        fill: am5.color(self.mapStyle == 0 ? "#1a1a1a" : "#ffffff"), // dark: #1a1a1a, white: #ffffff
        fillOpacity: 1,
      })
    );
      polygonSeries.mapPolygons.template.setAll({
      tooltipText: "{name}",
      toggleKey: "active",
      interactive: true,
    });
 

let previousPolygon;

polygonSeries.mapPolygons.template.on("active", function (active, target) {
  if (previousPolygon && previousPolygon != target) {
    previousPolygon.set("active", false);
  }
  if (target.get("active")) {
    polygonSeries.zoomToDataItem(target.dataItem );
  }
  else {
    chart.goHome();
  }
  previousPolygon = target;
});

  await Promise.all([
    axios.get(process.env.VUE_APP_REST_API_URL+'/api/landmarks').then((landmarks) => {
          self.dataPlaces = landmarks.data;
             axios.get(process.env.VUE_APP_REST_API_URL+'/api/cities').then((total) => {
                self.totalProjects = total.data;
                  // console.log(self.totalProjects)
                  showProjects(this.totalProjects)
                  setImages(this.dataPlaces)
             })
            //  console.log(self.totalProjects)
      })
      
 ]);
 
// Create main polygon series for countries
// https://www.amcharts.com/docs/v5/charts/map-chart/map-polygon-series/
// AQ: Exclude Antartica

 // Point Series


 
  // Prezoom function
 // ZOOM TO APAC COORDINATES
 // LOCATIONS = SINGAPORE, KUALA LUMPUR, MANILA

// polygonSeries.events.on("datavalidated", function() {
//  chart.zoomToGeoPoint({
//         lngitude: 147.62,
//         latitude: 2
//       }, 3, 0);
// });

// FOR IMAGES

function setImages(datas) {
 
  datas.forEach(elm => {
 let Landmarks = chart.series.push(am5map.MapPointSeries.new(root, {
  latitudeField: "lat",
  longitudeField: "lng"
}));
Landmarks.data.setAll([{
  lng: elm.geometry.lng,
  lat: elm.geometry.lat,
  src: elm.img.url,
  text: elm.city
}]); 

// Pictures
  Landmarks.bullets.push(function() {
    return am5.Bullet.new(root, {
      sprite: am5.Picture.new(root, {
        width: elm.img.width,
        height: elm.img.height, 
        centerX: elm.city == 'Singapore' ? am5.percent(10) : (elm.city == 'KualaLumpur' ? am5.percent(120) : am5.percent(90)),
        centerY: elm.city == 'Delhi' || elm.city == 'New York' ? am5.percent(105) : am5.percent(110),
        src: process.env.VUE_APP_REST_API_URL+elm.img.url,
        opacity: elm.city == 'Dubai' ? 0.95 : (elm.city == 'Tokyo' ? 1 : 0.35)
      }),
    });
  });

  });

}

function showProjects(data) {
console.log(data)
data.forEach(elm => {
    let pointSeriesPlaces = chart.series.push(am5map.MapPointSeries.new(root, {
  latitudeField: "lat",
  longitudeField: "lng"
}));
  
pointSeriesPlaces.data.setAll([{
  lng: elm.lng,
  lat: elm.lat,
  // src: elm.props.img,
  text: elm.city
}]); 

var name = elm.city;

if(elm.totalProjects > 1) {
pointSeriesPlaces.bullets.push(function() {
  let circle = am5.Circle.new(root, {
    radius: 8,
    fill: am5.color(self.mapStyle == 0 ? "#D3D3D3" : "#000"),
    // fillPattern: am5.LinePattern.new(root, {
    //   color: am5.color("#090909"),
    //   rotation: 45,
    //   width: 200,
    //   height: 200,
    //   gap: 2
    //   }),
    cursorOverStyle: "pointer",
    shadowColor: am5.color("#FFFFFF"),
    shadowBlur: '45',
    shadowOffsetX: 5,
    shadowOffsetY: 5,
    shadowOpacity: 1,
    // strokeOpacity: 0
  });

  circle.events.on("click", function() {
      console.log(elm.city)
      // click event
    alert("Clicked on " + elm.city)

  });
   return am5.Bullet.new(root, {
    sprite: circle
  });
});

pointSeriesPlaces.bullets.push(function() {
  let circle = am5.Circle.new(root, {
    radius: 1,
     centerX: am5.percent(50),
    centerY: elm.city == 'Singapore' || elm.city == 'Paris' || elm.city == 'Toronto' || elm.city == 'San Francisco' || elm.city == 'Hong Kong' || elm.city == 'Bangalore' ? am5.percent(-2850) : (elm.city == 'London' || elm.city == 'Shenzhen' ? am5.percent(2000) : am5.percent(4000)),
    fill: am5.color(self.mapStyle == 0 ? "#D3D3D3" : "#D3D3D3"),
    tooltip: am5.Tooltip.new(root, {
        
    }),
    tooltipText: elm.totalProjects.length == 0 ? '' : `${name} : [bold]${elm.totalProjects}[/]`,
    showTooltipOn: 'always',
    snapToolTip: true,
    cursorOverStyle: "pointer",
    // strokeOpacity: 0
  });
 
   return am5.Bullet.new(root, {
    sprite: circle
  });
});
 if(elm.totalProjects > 1) {
pointSeriesPlaces.bullets.push(function() {
    let aa = am5.Line.new(root, {
    stroke: am5.color(self.mapStyle == 0 ? "#D3D3D3" : "#030303"),
    height: elm.city == 'Singapore' || elm.city == 'Paris' || elm.city == 'Toronto' || elm.city == 'San Francisco' || elm.city == 'Hong Kong' || elm.city == 'Bangalore' ? 40 : (elm.city == 'London' || elm.city == 'Shenzhen' ? -50 : -80),
    strokeGradient: am5.LinearGradient.new(root, {
      stops: [
        { opacity: 1 },
        { opacity: 1 },
        { opacity: 0 }
      ]
    })
  });
     return am5.Bullet.new(root, {
    sprite: aa
  });
  });
 }

}

});


  }
 

 
// Add zoom control
// https://www.amcharts.com/docs/v5/charts/map-chart/map-pan-zoom/#Zoom_control
// chart.set("zoomControl", am5map.ZoomControl.new(root, {}));


// Set clicking on "water" to zoom out

chart.chartContainer.get("background").events.on("click", function () {
  chart.goHome();
})


// Make stuff animate on load
chart.appear(1000, 100);

  },
  methods: {
     slugify: (str) => {
     return str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '')
		.replace('-', '');
  },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  z-index: 99999;
}

.hello {
    /* position: relative; */
}
/* #chartdiv {
    width: 750px;
    height: 750px;
} */

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
